<template>
  <div>
    <div class="flex items-center">
      <!-- <vs-avatar :src="params.data.avatar" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" /> -->
      <vs-button @click="handleInstall" class="mt-1">Add to website</vs-button>
      <!-- <span v-if="!isEditable">{{ params.value }}</span> -->
    </div>

    <vs-popup 
      title="Widget Embed Instructions" 
      :active.sync="showPopup"
      class-content="install-widget-popup"
      data-backdrop="static"
      data-keyboard="false"
    >
      <h5>Elements Script</h5>
      <p class="mt-3">Copy and paste this code into the head section of your page HTML. This only needs to be added once per website or page, no matter how many widgets you place on the page.</p>
      <div class="embed-code mt-3 p-3">
        <vs-button  class="float-right ml-2"  
          v-clipboard:copy="elementScript"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError">Copy
        </vs-button>
        <p class="embed-script">{{elementScript}}</p>
      </div>
      
      <h5 class="mt-8">Widget Installation Code</h5>
      <p class="mt-3">Copy and paste this code into the desired place of your widget (HTML editor, website template, theme, etc).</p>
      <div class="embed-code mt-3 p-3">
        <vs-button  class="float-right ml-2"
          v-clipboard:copy="installationScript"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError">Copy</vs-button>
        <p class="embed-script">{{installationScript}}</p>
      </div>
      <h5 class="mt-8">Using the Duda platform plugin to add your widgets?</h5>
      <p class="mt-3">If you are using the Duda <strong>Travelgenix Widgets</strong> site widget, just select this widget from the dropdown and enter the following Widget ID in the field: <strong>{{getWidgetId}}</strong></p>
      <h5 class="mt-8">Looking for more help?</h5>
      <p class="mt-3">
        Click <a href="https://support.travelgenix.io/hc/en-gb/categories/360003654398--Website-Integrations" target="_blank" rel="nofollow">here</a> to read our support article on adding widgets to your website.
      </p>
    </vs-popup>
  </div>
  
</template>

<script>
export default {
  name: 'CellRendererInstallButton',
  data () {
    return {
      showPopup: false,
      elementScript: '<script src="https://static.travelify.io/travelify-elements-v1.0.js" integrity="sha512-TeuOMwttHUA+dk4DWHURBgBpRitNvXpCkpTe1UqT9lVlX9kRmaSnIdNo9Nw1UN4EAreRrUXY7i+mba21mLfB4w==" crossorigin="anonymous" defer="defer"><\/script>'
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    installationScript () {
      return this.params.data.widgetInstaller
    },
    getWidgetName () {
      return this.params.data.widgetRealName
    },
    getWidgetId () {
      return this.params.data.widgetInstanceId
    }
  },
  methods: {
    handleInstall () {
      this.showPopup = true
    },
    onCopy() {
      this.$_notifySuccess('Script copied successfully')
    },
    onError() {
      this.$_notifyFailure('Oops script not copied')
    },
  }
}
</script>
<style lang="scss">
.install-widget-popup .embed-code{
  border: 1px solid #dee2e6!important;

  .embed-script {
    color: #e83e8c;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 11x;
  }
}
/**
Override vs-popup's default width of 600px
 */
// .con-vs-popup .vs-popup{
//   width: 720px !important;
// }
</style>
